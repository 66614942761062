import './index.scss'

export default function FunctionalItem(props: {icon: any, title: string, children: string}) {
    return (
        <div className={"functional-item"}>
            {props.icon}
            <h5>{props.title}</h5>
            <p>{props.children}</p>
        </div>
    )
}
