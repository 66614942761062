import CoFoundersIcon from '../../../assets/img/icon-cofounders.png';
import './index.scss';

export default function About() {
    return (
        <div className={"home-about"}>
            <div className={"inner-home-about"}>
                <div className={"about-info"}>
                    <div className={"text-frame"}>
                        <h1>Built by working parents for working parents.</h1>
                        <p>
                            We believe every family deserves a stress&#x2011;free and well&#x2011;organized parental leave journey &#x2011;&#x2011; something our co&#x2011;founders both desperately needed when they took maternity leave.
                        </p>

                        <p>
                            Danielle and Michelle personally struggled with the complexities of transitioning to and from work, particularly while navigating postportum depression (PPD) from crafting transition plans from a blank sheet of paper to managing disorganized documents upon their return, it fueled their determination to find a smarter solution.
                        </p>

                        <p>
                            Combining their web development expertise and personal experiences, they embarked on a mission to create an online collaborative tool that streamline parental leave transition planning for parents and teams of all sizes and location.
                        </p>

                        <p>
                            Mola isn't just a software application; it's a solution born from real&#x2011;life challenges, empowering both working parents and businesses.
                        </p>
                    </div>
                    <div className={"picture-frame"}>
                        <img src={CoFoundersIcon} />
                        <p>Founders Danielle Lewis & Michelle Pittell</p>
                    </div>
                </div>
            </div>

        </div>
    )
}
