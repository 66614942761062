import Header from "../../shared/header";
import Introduction from "./introduction";
import Functionalities from "./functionalities";
import Newsletter from "./newsletter";
import About from "./about";
import Footer from "../../shared/footer";

export default function Home () {
    return (
        <div className={"body"}>
            <Header />
            <main>
                <Introduction />

                <Functionalities />

                <Newsletter />

                <About />
            </main>

            <Footer />
        </div>
    )
}
