import {ReactComponent as AddUserIcon} from "../../assets/icon/add-user.svg";
import {ReactComponent as Logo} from "../../assets/icon/mola-logo.svg";
import './index.scss';
import IconButton from "../icon-button";

export default function Header() {
    return (
        <header className={"main-header d-flex justify-content-between"}>
            <a href={""} className={'d-flex align-items-center'}><Logo /></a>
            <nav>
                <ul className={"d-flex"}>
                    <li>
                        <IconButton
                            href={""}
                            icon={<AddUserIcon />}
                            bg={'#F7BE5A'}
                            color={"#3A3A3A"}
                        >Get Started</IconButton>
                    </li>
                    <li><a href={""}>Pricing</a></li>
                    <li><a href={""}>Log In &gt;</a></li>
                </ul>
            </nav>
        </header>
    )
}
