import './index.scss';

export default function IconButton(props: {href: string, icon: any, children: string, bg: string, color: string, className?: string}) {
    return (
        <a className={"icon-button " + props.className} href={props.href}
           style={
               {
                   backgroundColor: props.bg,
                   color: props.color
               }
           }>
            {props.icon}
            <span>{props.children}</span>
        </a>
    )
}
