import {ReactComponent as Logo} from "../../assets/icon/mola-logo.svg";
import IconButton from "../icon-button";
import {ReactComponent as AddUserIcon} from "../../assets/icon/add-user.svg";
import IconInstagram from '../../assets/img/instagram-icon.png';
import {ReactComponent as IconLinkedin} from '../../assets/icon/icon-linkedin.svg';
import './index.scss';

export default function Footer () {
    return (
        <footer className={'shared-footer'}>
            <div className={"inner-shared-footer d-flex justify-content-between align-items-center"}>
                <a href={"/"} className={'d-flex align-items-center'}><Logo /></a>

                <ul className={'d-flex'}>
                    <li>
                        <a href={"https://www.instagram.com/meet_mola"}>
                            <img src={IconInstagram} alt="Instagram Icon"/>
                        </a>
                    </li>
                    <li>
                        <a href={"https://www.linkedin.com/company/meetmola/"}>
                            <IconLinkedin />
                        </a>
                    </li>
                </ul>

                <nav>
                    <ul className={"d-flex"}>
                        <li>
                            <IconButton className={"d-none"}
                                href={""}
                                icon={<AddUserIcon />}
                                bg={'#F7BE5A'}
                                color={"#3A3A3A"}
                            >Get Started</IconButton>
                        </li>
                        <li><a href={"mailto:hello@meetmola.com"}>Contact Us</a></li>
                        <li><a href={"#newsletter-section"}>Newsletter</a></li>
                    </ul>
                </nav>
            </div>

            <div className={'shared-footer-strip d-flex align-items-center justify-content-center'}>
                <p>&copy; Copyright Mola LLC</p>
            </div>
        </footer>
    )
}
