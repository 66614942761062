import {InputGroup, Form, Button} from "react-bootstrap";
import {ReactComponent as IconLeaf} from '../../../assets/icon/icon-leaf.svg'
import './index.scss';

export default function Newsletter() {
    return (
        <div className={"home-newsletter"} id={"newsletter-section"}>
            <IconLeaf className={"tied-up"} />
            <h1>Coming Soon</h1>
            <h4>Be the first to know. Join the Waitlist.</h4>
            <Form action={"https://meetmola.us13.list-manage.com/subscribe/post"} method={"POST"}>
                <Form.Control type="hidden" name="u" value="6877fcb19fd50ff4564aa34df"/>
                    <Form.Control type="hidden" name="id" value="f9e6e36705"/>
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Email Address"
                        aria-label="Email Address"
                        name={"MERGE0"}
                        aria-describedby="email-newsletter"
                    />
                    <Form.Control type={"submit"} id="email-newsletter" name={"submit"} value={"Join Waitlist"}/>
                </InputGroup>
            </Form>

        </div>
    )
}
