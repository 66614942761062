import './index.scss'
import {ReactComponent as IntroBg} from '../../../assets/icon/intro-bg.svg';
import {ReactComponent as BannerImage} from '../../../assets/icon/introduction-image.svg';

export default function Introduction() {
    return (
        <section className={'home-introduction'}>
            <IntroBg className={'home-introduction-bg'} />
            <h1>Every family deserves a stress-free parental leave.</h1>
            <p>Say goodbye to the hassle of piecing together a parental leave plan from scattered documents and internet searches.</p>
            <BannerImage className={'home-introduction-banner'}/>
        </section>
    )
}
