import './index.scss';
import FunctionalItem from "../../../shared/functional-item";
import {ReactComponent as IconTeamCollaboration} from "../../../assets/icon/team-collaboration.svg";
import {ReactComponent as IconDocumentShare} from "../../../assets/icon/share-icon.svg";
import {ReactComponent as IconProject} from "../../../assets/icon/project-icon.svg";
import {ReactComponent as IconLeaveManagement} from "../../../assets/icon/leave-management-icon.svg";
import {ReactComponent as IconSharePlan} from "../../../assets/icon/share-plan-icon.svg";
import {ReactComponent as IconStressTest} from "../../../assets/icon/icon-stress-test.svg";

export default function Functionalities() {
    return (
        <div className={"home-functionalities"}>
            <div className={"inner-home-functionalities"}>
                <h2>Mola is a collaborative app that streamlines parental leave for working parents and teams, everywhere.</h2>
                <div className={"row"}>
                    <div className={"col-lg-4"}>
                        <FunctionalItem
                            icon={<IconTeamCollaboration />}
                            title={"Seamless Team Collaboration"}
                        >Invite and collaborate with unlimited team members in one central location.</FunctionalItem>
                    </div>
                    <div className={"col-lg-4"}>
                        <FunctionalItem
                            icon={<IconDocumentShare />}
                            title={"Store & Share Documents"}
                        >Securely upload documents with the flexibility to share them with the HR or your team.</FunctionalItem>
                    </div>
                    <div className={"col-lg-4"}>
                        <FunctionalItem
                            icon={<IconProject />}
                            title={"Manage Projects & Meetings"}
                        >Organize your projects and meetings for a smooth transition to and from leave.</FunctionalItem>
                    </div>

                    <div className={"col-lg-4"}>
                        <FunctionalItem
                            icon={<IconLeaveManagement />}
                            title={"Seamless Leave Management"}
                        >Experience personalized 'My Leave' features for easy and organized leave management.</FunctionalItem>
                    </div>
                    <div className={"col-lg-4"}>
                        <FunctionalItem
                            icon={<IconSharePlan />}
                            title={"Share Plan with Ease"}
                        >Download your leave plan for easy sharing with others.</FunctionalItem>
                    </div>
                    <div className={"col-lg-4"}>
                        <FunctionalItem
                            icon={<IconStressTest />}
                            title={"Stress Testing"}
                        >Proactively anticipate and address potential challenges before your leave begins.</FunctionalItem>
                    </div>
                </div>
            </div>

        </div>
    )
}
